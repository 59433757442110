import { Component, OnInit, Input } from '@angular/core';
import { EventsComponent } from '../events.component';
import { EventsService } from 'src/app/services/events.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-update-event-modal',
  templateUrl: './update-event-modal.component.html',
  styleUrls: ['./update-event-modal.component.scss']
})
export class UpdateEventModalComponent implements OnInit {
  @Input() eventSelected: any;
  spinner = false;
  error = false;

  constructor(
    private modalService: NgbModal,
    private eventsService: EventsService,
    private eventsComponent: EventsComponent
  ) { }

  ngOnInit() {
  }

  open(modal) {
    this.modalService.open(modal);
  }

  async update() {
    try {
      this.spinner = true;

      const { id, name } = this.eventSelected;
      const response: any = await this.eventsService.update(id, { name }).toPromise();
      if (!response.success) {
        console.log(`ERRO AO CRIAR EVENTO ${response}`);
        this.error = true;

        return;
      }

      this.modalService.dismissAll();
      this.eventsComponent.ngOnInit();

      this.spinner = false;
    } catch (err) {
      this.error = true;
      this.spinner = false;

      console.log(err);
    }
  }

}
