import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) { }

  token = this.sessionService.currentUserValue.token;
  
  listFilterPager(filter) {
    return this.http.get<any>(`${environment.apiUrl}/business/listFilterPager`, { params: filter });
  }

  getBusinesses() {
    return this.http.get<any>(`${environment.apiUrl}/business`);
  }

  getBusinessesByTaskStatus(status){
    return this.http.get<any>(`${environment.apiUrl}/businesses/training/task/${status}`);
  }

  getBusinessesTraining(filter){
    return this.http.get<any>(`${environment.apiUrl}/businesses/training`, { params: filter });
  }

}
