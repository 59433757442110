import { Component } from "@angular/core";
import { Router, NavigationStart, ActivatedRoute } from "@angular/router";
import { SessionService } from "./services/session.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  ngOnInit() {}

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: SessionService
  ) {
    router.events.subscribe((event: any) => {
      if (event.url == "/") {
        this.router.navigate([
          `/${
            this.sessionService.currentUserValue.user.bo_default_url ||
            "businesses"
          }`,
        ]);
      } else if (event.url && event.url.includes("/#!/solicitacoes")) {
        const args = event.url.split("/");
        if (+args[args.length - 1]) {
          this.router.navigate(["/task", +args[args.length - 1]]);
        }
      } else if (event.url && event.url.includes("/#!/prestadores")) {
        const args = event.url.split("/");
        if (+args[args.length - 1]) {
          this.router.navigate(["/businesses", +args[args.length - 1]]);
        }
      } else if (event.url && event.url.includes("/#!/usuarios")) {
        const args = event.url.split("/");
        if (+args[args.length - 1]) {
          this.router.navigate(["/user", +args[args.length - 1]]);
        }
      } else if (event.url && event.url.includes("/#!/companies")) {
        const args = event.url.split("/");
        if (+args[args.length - 1]) {
          this.router.navigate(["/company", +args[args.length - 1]]);
        }
      }
    });
  }
}
