import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }

  public get currentUserValue(){
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  public get isAuthenticated() {
    return !!this.currentUserValue;
  }

  public get userHasEmailFromTriider() {
    const currentUser = this.currentUserValue?.user

    if (!currentUser) return null

    const userHasEmailFromTriider = currentUser.email.includes('@triider.com')

    return userHasEmailFromTriider
  }

  setSession(data) {
    data.user.profile_picture = data.user.profile_picture || "https://icon-library.net/images/default-user-icon/default-user-icon-8.jpg";
    localStorage.setItem('currentUser', JSON.stringify({
      user: data.user,
      token: data.token
    }));
  }

  removeSession() {
    localStorage.removeItem('currentUser');
  }
}
