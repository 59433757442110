import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-log',
  templateUrl: './search-log.component.html',
  styleUrls: ['./search-log.component.scss']
})
export class SearchLogComponent implements OnInit {
  iframe = 'buscarlog';
  constructor() { }

  ngOnInit() {
  }

}
