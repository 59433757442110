import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class RolesGuardService implements CanActivate {

  constructor(private router: Router, private sessionService: SessionService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const currentUser = this.sessionService.currentUserValue;
    if (currentUser) {
      // if (next.data.roles && next.data.roles.indexOf(currentUser.user.role) === -1) {
      //     this.router.navigate(['/']);
      //     return false;
      // }

      return true;
    }
  }
}
