import { Component, OnInit, ViewChild } from '@angular/core';
import { DistributionService } from '../services/distribution.service';
import { ServiceService } from '../services/service.service';
import { HelperService } from '../services/helper.service';

@Component({
  selector: 'app-distributions',
  templateUrl: './distributions.component.html',
  styleUrls: ['./distributions.component.scss']
})
export class DistributionsComponent implements OnInit {
  services;
  cities;
  distributions;
  page = 1;
  totalItems = 0;
  algorithms;
  markets = [{ text: "b2b" }, { text: "b2c" }];
  searchOrder = false;
  servicesSelected;
  algorithmSelected;
  citiesSelected;
  marketSelected;
  filter: any = {
    offset: this.page === 1 ? 0 : (this.page - 1) * 10
  };

  constructor(
    private distributionService: DistributionService,
    private serviceService: ServiceService,
    private helperService: HelperService
  ) { }

  ngOnInit() {
    this.distributions = [];

    this.search();

    this.distributionService.listAlgorithms().subscribe(result => this.algorithms = result.algorithms);

    this.serviceService.getAllServices().subscribe(result => this.services = result.services);

    this.helperService.getAllCities().subscribe(result => this.cities = result.cities);
  }

  async delete(id) {
    await this.distributionService.delete(id).subscribe((result: any) => {
      if (!result.success)
        console.log(`FALHA AO REMOVER DISTRIBUIÇÃO ${result}`);

    });

    this.ngOnInit();
  }

  sort() {
    if (!this.searchOrder) {
      this.searchOrder = true;
      this.distributions.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
    } else {
      this.searchOrder = false;
      this.distributions.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
    }
  }

  search() {
    this.filter = {
      offset: this.page === 1 ? 0 : (this.page - 1) * 15
    };

    if (this.marketSelected != undefined)
      if (this.marketSelected.length > 0)
        this.filter = { markets: this.marketSelected.map(market => market.text), ...this.filter };

    if (this.servicesSelected != undefined)
      if (this.servicesSelected.length > 0)
        this.filter = { services: this.servicesSelected.map(service => service.id), ...this.filter };

    if (this.algorithmSelected != undefined)
      if (this.algorithmSelected.length > 0)
        this.filter = { algorithms: this.algorithmSelected.map(algorithm => algorithm.id), ...this.filter };

    if (this.citiesSelected != undefined)
      if (this.citiesSelected.length > 0)
        this.filter = { cities: this.citiesSelected.map(cities => cities.id), ...this.filter };

    this.distributionService.searchFilter(this.filter).subscribe(result => {
      if (!result.success)
        console.log(`ERRO AO FILTRAR DISTRIBUIÇÃO ${result}`);

      this.distributions = result.distributions.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
      this.totalItems = this.distributions[0].total_rows;
    });
  }

  pageChanged() {
    this.search();
  }

}
