import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BusinessService } from 'src/app/services/business.service';
import { EventsService } from 'src/app/services/events.service';
import { BusinessTrainingComponent } from '../business-training.component';

@Component({
  selector: 'app-add-businesses-modal',
  templateUrl: './add-businesses-modal.component.html',
  styleUrls: ['./add-businesses-modal.component.sass']
})
export class AddBusinessesModalComponent implements OnInit {
  businessesTraining: any = {};
  businessTermTraining: any;
  @Input() event: any;
  error = false;
  constructor(
    private modalService: NgbModal,
    private businessService: BusinessService,
    private eventService: EventsService,
    private businessTraining: BusinessTrainingComponent
  ) { }

  async ngOnInit() {
    await this.search();
  }

  open(modal) {
    this.modalService.open(modal, { size: 'lg' });
  }

  async getBusinessTrainingEvent() {
    return (await this.eventService.listBusinessesEvent({}).toPromise()).businessesEvent;
  }

  async addBusinessEvent(business) {
    business.clicked = true;
    try {
      const businessEvent = await this.eventService.createBusinessEvent({ business_id: business.id, event_id: this.event }).toPromise();

      if (!businessEvent.success)
        this.error = true;

      await this.search();
      await this.businessTraining.ngOnInit();

    } catch (error) {
      this.error = true;
      console.log(error);
    }
  }

  async search() {
    let filter = {};
    
    if (this.businessTermTraining)
      filter = {
        term: this.businessTermTraining
      }

    this.businessesTraining = (await this.businessService.getBusinessesTraining(filter).toPromise()).businesses;
  }
}
