import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from '../services/events.service';
import { TaskService } from '../services/task.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-business-training',
  templateUrl: './business-training.component.html',
  styleUrls: ['./business-training.component.scss']
})
export class BusinessTrainingComponent implements OnInit {
  businessessTasksStatus: any = {};
  page = 1;
  totalItems;
  businessName: any;
  businesses: any = [];
  event: any = {};
  filter: any = {};
  loader = false;

  taskStatuses = [
    { value: 'sem-orcamento', text: 'Sem orçamento' },
    { value: 'com-orcamento', text: 'Com orçamento' },
    { value: 'aguardando-aprovacao', text: 'Aguardando aprovação (B2B)' },
    { value: 'fechado', text: 'Fechado' },
    { value: 'aguardando-pagamento', text: 'Aguardando pagamento' },
    { value: 'pago', text: 'Pago' },
    { value: 'avaliado', text: 'Avaliado' },
    { value: 'done', text: 'Finalizado' },
    { value: 'cancelado', text: 'Cancelado' }
  ];
  isTaskStatusSelected = [];

  constructor(
    private taskService: TaskService,
    private eventsService: EventsService,
    private toastr: ToastrService,
    private router: ActivatedRoute) { };


  async ngOnInit() {
    const eventId = this.router.snapshot.params['eventId'];
    this.event = (await this.eventsService.get(eventId).toPromise()).event;

    this.filter = {
      limit: 15,
      offset: 0,
      event_id: this.event.id
    }

    this.search();
  }

  async getBusinesses() {
    this.loader = true;
    const businessess = (await this.eventsService.listBusinessesEvent(this.filter).toPromise()).businessesEvent;
    this.loader = false;
    return businessess;
  }

  async search() {
    this.filter.offset = this.page === 1 ? 0 : (this.page - 1) * 15

    if (this.businessName || this.businessName == '')
      this.filter.term = this.businessName;

    this.businesses = await this.getBusinesses();
    this.totalItems = this.businesses.length;
  }

  selectIsEmpty(select) {
    return select.length == 0;
  }

  async removeBusinessEvent(business_id) {
    try {
      const event_id = this.event.id;
      const deleteBusinessEvent = await this.eventsService.removeBusinesEvent({ business_id, event_id }).toPromise();

      if (deleteBusinessEvent.success)
        this.businesses = await this.getBusinesses();

    } catch (error) {
      console.log(error);
    }
  }

  async setAction(action) {
    this.loader = true;
    try {
      switch (action) {
        case 'create_task':
          await this.taskService.createTasksTraining(this.event.id).toPromise();

          this.search();
          this.loader = false;
          this.toastr.success("Pedidos criados com sucesso!");
          break;
        case 'hire_task':
          await this.taskService.hireTasksTraining(this.event.id).toPromise();

          this.search();
          this.loader = false;
          this.toastr.success("Profissionais contratados com sucesso!");
          break;
        case 'cancel_task':
          this.taskService.cancelTasksTraining(this.event.id).toPromise();

          this.search();
          this.loader = false;
          this.toastr.success("Pedidos cancelados com sucesso!");
          break;

        case 'send_message':
          this.eventsService.sendMessages(this.event.id).toPromise();

          this.search();
          this.loader = false;
          this.toastr.success(`Mensagens enviadas com sucesso!`);
          break;
      }
    } catch (error) {
      this.loader = false;
      this.toastr.error("Erro ao realizar ação!");
      console.log(error);
    }
  }

  async setActionByBusiness(action, business) {
    this.loader = true;
    try {
      switch (action) {
        case 'create_task':
          await this.taskService.createTasksTraining(this.event.id, business.user_id).toPromise();

          this.search();
          this.loader = false;
          this.toastr.success(`Pedidos criados com sucesso para o profissional ${business.name}!`);
          break;
        case 'hire_task':
          await this.taskService.hireTasksTraining(this.event.id, business.user_id).toPromise();

          this.search();
          this.loader = false;
          this.toastr.success(`Profissional ${business.name} contratado!`);
          break;
        case 'cancel_task':
          this.taskService.cancelTasksTraining(this.event.id, business.user_id).toPromise();

          this.search();
          this.loader = false;
          this.toastr.success(`Pedidos do profissional ${business.name} cancelados com sucesso!`);
          break;

        case 'send_message':
          this.eventsService.sendMessages(this.event.id, business.user_id).toPromise();

          this.search();
          this.loader = false;
          this.toastr.success(`Mensagem enviada ao profissional ${business.name}!`);
          break;
      }
    } catch (error) {
      this.loader = false;
      this.toastr.error("Erro ao realizar ação!");
      console.log(error);
    }
  }

  pageChanged() {
    this.search();
  }

}
