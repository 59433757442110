import { Component, OnInit } from '@angular/core';
import { EventsService } from '../services/events.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  events = [];
  statuses = [{ text: 'Ativo', value: true }, { text: 'Finalizado', value: false }];
  eventName;
  statusSeleteced: any;
  totalItems;
  page = 1;
  filter: any = {
    limit: 10,
    offset: this.page === 1 ? 0 : (this.page - 1) * 10
  };

  constructor(
    private eventsService: EventsService
  ) { }

  async ngOnInit() {
    await this.search();
  }

  async finish(id) {
    try {
      const response: any = await this.eventsService.update(id, { activated: false }).toPromise();
      if (!response.success) {
        console.log(`ERRO AO CRIAR EVENTO ${response}`);

        return;
      }

      this.ngOnInit();
    } catch (err) {
      console.log(err);
    }
  }

  async search() {
    this.filter.offset = this.page === 1 ? 0 : (this.page - 1) * 10;

    if (this.statusSeleteced) 
      this.filter.activated = this.statusSeleteced.value;
    else 
      delete this.filter.activated;

    if(this.eventName || this.eventName == '')
      this.filter.term = this.eventName;

    this.events = (await this.eventsService.listAll(this.filter).toPromise()).events;
    this.totalItems = this.events.length == 0 ? 0 : this.events[0].total_rows;
  }

  pageChanged() {
    this.search();
  }

}
