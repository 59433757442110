import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DistributionService } from '../../services/distribution.service';

@Component({
  selector: 'app-algorithm-modal',
  templateUrl: './../algorithm-modal/algorithm-modal.component.html',
  styleUrls: ['./../algorithm-modal/algorithm-modal.component.scss']
})
export class AlgorithmModalComponent implements OnInit {
  algorithms;
  selectedAlgorithm;

  constructor(
    private modalService: NgbModal,
    private distributionService: DistributionService
  ) { }

  ngOnInit() {
    this.distributionService.listAlgorithms().subscribe(result => this.algorithms = result.algorithms);
  }

  open(content, algorithm) {
    if (algorithm)
      this.selectedAlgorithm = algorithm;
      
    this.modalService.open(content, { size: 'lg' });
  }
}
