import { Component, OnInit } from '@angular/core';
import { SessionService } from '../services/session.service';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  fileName;
  profilePicture;
  formData;
  currentUser = this.sessionService.currentUserValue;
  userHasEmailFromTriider = this.sessionService.userHasEmailFromTriider;
  loading = false;
  editNameDisabled = true;
  editPhoneDisabled = true;
  editPasswordDisabled = true;
  editPasswordResponse;
  loadingPassword = false;
  defaultPage;
  pagesToUserWithTriiderEmail = [
    { name: 'Cupons', path: 'coupon' },
    { name: 'Consulta de Login', path: 'search-log' },
    { name: 'Chats', path: 'chats' },
    { name: 'Videos do Triider', path: 'videos' },
    { name: 'Notificações', path: 'notification' },
    { name: 'Distribuições', path: 'distributions' },
    { name: 'Eventos', path: 'events' },
  ];
  pages = [
    { name: 'Usuários', path: 'user' },
    { name: 'Profissionais', path: 'businesses' },
    { name: 'Pedidos', path: 'task' },
    { name: 'Empresas', path: 'company' },
    ...(this.userHasEmailFromTriider ? this.pagesToUserWithTriiderEmail : [])
  ]

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private userService: UserService) { }

  ngOnInit() {
    this.defaultPage = this.currentUser.user.bo_default_url;
    this.profilePicture = this.currentUser.user.profile_picture || "https://icon-library.net/images/default-user-icon/default-user-icon-8.jpg";
  }

  changePicture(files) {
    this.loading = true;
    let user = this.currentUser.user;
    let token = this.currentUser.token;

    let photo = files[0];
    this.fileName = photo.name;

    this.formData = new FormData();
    this.formData.append('image', photo);

    this.userService.uploadPicture(this.formData, this.currentUser).subscribe(resp => {
      user.profile_picture = resp.imageUrl;

      this.userService.updateUser(user).subscribe(resp => {
        if (resp.success) {
          this.sessionService.setSession({ user, token });
          this.loading = false;
          this.ngOnInit();
        }
      });;

    });;
  }

  editUser(value, property) {
    let user = this.currentUser.user;
    let token = this.currentUser.token;

    if (property == 'name') {
      user.name = value;
      this.editNameDisabled = true;
    } else if (property == 'phone') {
      user.phone = value;
      this.editPhoneDisabled = true;
    }

    this.userService.updateUser(user).subscribe(resp => {
      if (resp.success) {
        this.sessionService.setSession({ user, token });
        this.ngOnInit();
      }
    });;
  }

  editPassword(password, newPassword, retypedPassword) {
    this.loadingPassword = true;
    if (newPassword != retypedPassword)
      return this.editPasswordResponse = 'As senhas devem ser iguais.';

    this.userService.changePassword(password, newPassword, this.currentUser.token)
      .subscribe(
        resp => {
          if (resp.success) {
            this.ngOnInit();
            this.loadingPassword = false;
            this.editPasswordDisabled = true;
          }
        },
        err => {
          this.loadingPassword = false;
          this.editPasswordResponse = err.error.error;
        }
      );
  }

  changeDefaultPage(event){
    let user = this.currentUser.user;
    let token = this.currentUser.token;

    user.bo_default_url = event;

    this.userService.updateUser(user).subscribe(resp => {
      if (resp.success) {
        this.sessionService.setSession({ user, token });
        this.ngOnInit();
      }
    });;
  }
}
