import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) { }

  token = this.sessionService.currentUserValue.token;

  getAllCities() {
    return this.http
      .get<any>(`${environment.apiUrl}/city/`);
  }

  getAllStates() {
    return this.http
      .get<any>(`${environment.apiUrl}/state/`);
  }

  getCities(state) {
    return this.http
      .get<any>(`${environment.apiUrl}/state/${state}/business_city`);
  }
}
