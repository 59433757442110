import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { SessionService } from './../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient, private sessionService: SessionService) { }

  login(user) {
    const headers = new HttpHeaders({ 'No-Auth': 'true' });
    headers.append('Content-Type', 'application/json');
    
    return this.http
      .post<any>(`${environment.apiUrl}/login/`, user, { headers: headers });
  }

  logout() {
    this.sessionService.removeSession();
  }
}
