import { Component, OnInit, ViewChild } from '@angular/core';
import { SessionService } from '../services/session.service';
import { SearchFiltersBusinessComponent } from '../search-filters-business/search-filters-business.component';

@Component({
  selector: 'app-businesses',
  templateUrl: './businesses.component.html',
  styleUrls: ['./businesses.component.scss']
})
export class BusinessessComponent implements OnInit {
  @ViewChild(SearchFiltersBusinessComponent, { static: true }) private searchFilters;
  businesses;
  token = this.sessionService.currentUserValue.token;
  page = 1;
  totalItems = 0;
  orderTypes = [
    { type: 'user_name', text: 'Nome' },
    { type: 'email', text: 'Email' },
    { type: 'city_name', text: 'Cidade' },
    { type: 'phone', text: 'Telefone' }
  ];
  iframe = 'prestadores';

  constructor(
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    // this.searchFilters.search();
  }

  getOutputValue(list) {
    this.businesses = list;
    this.totalItems = list.length == 0 ? 0 : list[0].total_rows;
  }

  pageChanged() {
    this.searchFilters.search();
  }

  orderBusinesses(order, dir) {
    this.searchFilters.orderType = order;
    this.searchFilters.orderDir = dir === 'desc' ? 'asc' : 'desc';

    this.searchFilters.search();
  }
}