import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(
    private http: HttpClient) { }

  createTasksTraining(event_id, user_id = null) {
    const event = {
      event_id,
      user_id
    };

    return this.http.post<any>(`${environment.apiUrl}/task/training/event/`, event);
  }

  hireTasksTraining(event_id, user_id = null) {
    const event = {
      event_id,
      user_id
    };

    return this.http.post<any>(`${environment.apiUrl}/task/training/event/hire`, event);
  }

  cancelTasksTraining(event_id, user_id = null) {
    const event = {
      event_id,
      user_id
    };

    return this.http.post<any>(`${environment.apiUrl}/task/training/event/archive`, event);
  }
}
