import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class OnlyUsersWithTriiderEmailGuardService implements CanActivate {

  constructor(private router: Router, private sessionService: SessionService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const userHasEmailFromTriider = this.sessionService.userHasEmailFromTriider;

    if (!userHasEmailFromTriider) {
      return this.router.navigate(['/tasks']);
    }

    return true
  }
}
