import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { BusinessessComponent } from "./businessess/businesses.component";
import { MenuComponent } from "./menu/menu.component";
import { AuthGuardService } from "./guards/auth-guard.service";
import { RolesGuardService } from "./guards/roles-guard.service";
import { UserComponent } from "./user/user.component";
import { TaskComponent } from "./task/task.component";
import { CompanyComponent } from "./company/company.component";
import { SearchLogComponent } from "./search-log/search-log.component";
import { CouponComponent } from "./coupon/coupon.component";
import { ChatsComponent } from "./chats/chats.component";
import { VideosComponent } from "./videos/videos.component";
import { ProfileComponent } from "./profile/profile.component";
import { SearchFiltersBusinessComponent } from "./search-filters-business/search-filters-business.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxPaginationModule } from "ngx-pagination";
import { IframeBoComponent } from "./iframe-bo/iframe-bo.component";
import { DistributionsComponent } from "./distributions/distributions.component";
import { AlgorithmModalComponent } from "./distributions/algorithm-modal/algorithm-modal.component";
import { NewDistributionModalComponent } from "./distributions/new-distribution-modal/new-distribution-modal.component";
import { MockHttpCalIInterceptor } from "./http.interceptor";
import { NotificationComponent } from "./notification/notification.component";
import { BusinessTrainingComponent } from "./business-training/business-training.component";
import { OpenTasksModalComponent } from "./business-training/open-tasks-modal/open-tasks-modal.component";
import { EventsComponent } from "./events/events.component";
import { InvoiceComponent } from "./invoice/invoice.component";
import { CreateEventModalComponent } from "./events/create-event-modal/create-event-modal.component";
import { UpdateEventModalComponent } from "./events/update-event-modal/update-event-modal.component";
import { AddBusinessesModalComponent } from "./business-training/add-businesses-modal/add-businesses-modal.component";
import { MgmComponent } from "./mgm/mgm.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BusinessessComponent,
    MenuComponent,
    UserComponent,
    TaskComponent,
    CompanyComponent,
    SearchLogComponent,
    CouponComponent,
    ChatsComponent,
    VideosComponent,
    ProfileComponent,
    SearchFiltersBusinessComponent,
    IframeBoComponent,
    DistributionsComponent,
    AlgorithmModalComponent,
    NewDistributionModalComponent,
    NotificationComponent,
    BusinessTrainingComponent,
    OpenTasksModalComponent,
    EventsComponent,
    InvoiceComponent,
    CreateEventModalComponent,
    UpdateEventModalComponent,
    AddBusinessesModalComponent,
    MgmComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    AuthGuardService,
    RolesGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MockHttpCalIInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
