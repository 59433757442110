import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mgm',
  templateUrl: './mgm.component.html',
  styleUrls: ['./mgm.component.scss']
})
export class MgmComponent implements OnInit {
  iframe = 'mgm-credit-request';
  constructor() { }

  ngOnInit() {
  }

}
