import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { BusinessessComponent } from "./businessess/businesses.component";
import { LoginComponent } from "./login/login.component";
import { MenuComponent } from "./menu/menu.component";
import { UserComponent } from "./user/user.component";
import { TaskComponent } from "./task/task.component";
import { CompanyComponent } from "./company/company.component";
import { CouponComponent } from "./coupon/coupon.component";
import { SearchLogComponent } from "./search-log/search-log.component";
import { ChatsComponent } from "./chats/chats.component";
import { VideosComponent } from "./videos/videos.component";
import { ProfileComponent } from "./profile/profile.component";
import { AuthGuardService } from "./guards/auth-guard.service";
import { RolesGuardService } from "./guards/roles-guard.service";
import { SearchFiltersBusinessComponent } from "./search-filters-business/search-filters-business.component";
import { DistributionsComponent } from "./distributions/distributions.component";
import { NotificationComponent } from "./notification/notification.component";
import { BusinessTrainingComponent } from "./business-training/business-training.component";
import { EventsComponent } from "./events/events.component";
import { InvoiceComponent } from "./invoice/invoice.component";
import { IframeBoComponent } from "./iframe-bo/iframe-bo.component";
import { AppComponent } from "./app.component";
import { MgmComponent } from "./mgm/mgm.component";
import { OnlyUsersWithTriiderEmailGuardService } from "./guards/only-users-with-triider-email-guard.service";

const routes: Routes = [
  { path: "login", component: LoginComponent },
  {
    path: "",
    component: MenuComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: "", component: AppComponent },
      { path: "profile", component: ProfileComponent },
      {
        path: "businesses",
        component: BusinessessComponent,
        children: [{ path: ":id", component: BusinessessComponent }],
      },
      { path: "events", component: EventsComponent, canActivate: [OnlyUsersWithTriiderEmailGuardService] },
      { path: "events/training", component: BusinessTrainingComponent, canActivate: [OnlyUsersWithTriiderEmailGuardService] },
      {
        path: "user",
        component: UserComponent,
        children: [{ path: ":id", component: UserComponent }],
      },
      {
        path: "task",
        component: TaskComponent,
        children: [{ path: ":id", component: TaskComponent }],
      },
      {
        path: "company",
        component: CompanyComponent,
        children: [{ path: ":id", component: CompanyComponent }],
      },
      { path: "coupon", component: CouponComponent, canActivate: [OnlyUsersWithTriiderEmailGuardService] },
      { path: "mgm-credit-request", component: MgmComponent, canActivate: [OnlyUsersWithTriiderEmailGuardService] },
      { path: "search-log", component: SearchLogComponent, canActivate: [OnlyUsersWithTriiderEmailGuardService] },
      { path: "chats", component: ChatsComponent },
      { path: "videos", component: VideosComponent, canActivate: [OnlyUsersWithTriiderEmailGuardService] },
      { path: "notification", component: NotificationComponent, canActivate: [OnlyUsersWithTriiderEmailGuardService] },
      { path: "invoice", component: InvoiceComponent, canActivate: [OnlyUsersWithTriiderEmailGuardService] },
      { path: "bo-iframe", component: IframeBoComponent },
      {
        path: "distributions",
        component: DistributionsComponent,
        canActivate: [RolesGuardService, OnlyUsersWithTriiderEmailGuardService],
      },
    ],
  },
  {
    path: "search-filters-business",
    component: SearchFiltersBusinessComponent,
    canActivate: [OnlyUsersWithTriiderEmailGuardService]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
