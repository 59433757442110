import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SessionService } from './services/session.service';

@Injectable()
export class MockHttpCalIInterceptor implements HttpInterceptor {
    token = this.sessionService.currentUserValue != null ? this.sessionService.currentUserValue.token : null;

    constructor(
        private injector: Injector,
        private sessionService: SessionService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.get('No-Auth'))
            return next.handle(req.clone());

        const dupReq = req.clone({
            headers: req.headers.set('api-token', this.token)
        });

        return next.handle(dupReq);
    }
}

