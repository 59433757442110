import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(
    private http: HttpClient) { }

  listAll(filter) {
    return this.http.get<any>(`${environment.apiUrl}/events`, { params: filter });
  }

  list(activated) {
    return this.http.get<any>(`${environment.apiUrl}/events/activated/${activated}`);
  }

  get(id) {
    return this.http.get<any>(`${environment.apiUrl}/event/${id}`);
  }

  create(event) {
    return this.http.post(`${environment.apiUrl}/event`, { name: event });
  }

  update(id, event) {
    return this.http.put<any>(`${environment.apiUrl}/event/${id}`, event);
  }

  createBusinessEvent(businessEvent){
    return this.http.post<any>(`${environment.apiUrl}/event/business`, businessEvent);
  }

  listBusinessesEvent(filter){
    return this.http.get<any>(`${environment.apiUrl}/event/business/list`, { params: filter });
  }

  removeBusinesEvent(businessEvent){
    const { event_id, business_id } = businessEvent;

    return this.http.delete<any>(`${environment.apiUrl}/event/${event_id}/business/${business_id}`);
  }

  sendMessages(event_id, user_id = null){
    const event = {
      event_id,
      user_id
    };
    
    return this.http.post<any>(`${environment.apiUrl}/event/business/message`, event);
  }
}
