import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventsService } from 'src/app/services/events.service';
import { EventsComponent } from '../events.component';

@Component({
  selector: 'app-create-event-modal',
  templateUrl: './create-event-modal.component.html',
  styleUrls: ['./create-event-modal.component.scss']
})
export class CreateEventModalComponent implements OnInit {
  eventName;
  spinner = false;
  error = false;

  constructor(
    private modalService: NgbModal,
    private eventsService: EventsService,
    private eventsComponent: EventsComponent
  ) { }

  ngOnInit() {
  }

  open(modal) {
    this.modalService.open(modal);
  }

  async create() {
    try {
      this.spinner = true;
      const response: any = await this.eventsService.create(this.eventName).toPromise();

      if (!response.success) {
        console.log(`ERRO AO CRIAR EVENTO ${response}`);
        this.error = true;

        return;
      }

      this.modalService.dismissAll();
      this.eventsComponent.ngOnInit();
      this.spinner = false;
      this.eventName = null;
    } catch (err) {
      this.error = true;
      this.spinner = false;

      console.log(err);
    }
  }

}