import { Component, OnInit } from '@angular/core';
import { SessionService } from '../services/session.service';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  profilePicture;

  currentUser = this.sessionService.currentUserValue;
  userHasEmailFromTriider = this.sessionService.userHasEmailFromTriider;

  isMenuOpen = false;
  menuItemsToTriiderUsers = [
    {
      path: '#',
      class: 'fas fa-gift',
      text: 'Promoções',
      subMenuItems: [
        {
          path: '/coupon',
          text: 'Cupons'
        },
        {
          path: '/notification',
          text: 'Notificações'
        },
        {
          path: '/mgm-credit-request',
          text: 'MGM'
        }
      ]
    },
    {
      path: '#',
      class: 'fas fa-money-check-alt',
      text: 'Financeiro',
      subMenuItems: [
        {
          path: '/invoice',
          text: 'Faturas'
        }
      ]
    },
    {
      path: '/notification',
      class: 'fas fa-cogs',
      text: 'Configurações',
      subMenuItems: [
        {
          path: '/distributions',
          text: 'Distribuições'
        },
        {
          path: '/videos',
          text: 'Vídeos do Triider'
        }
      ]
    },
    {
      path: '#',
      class: 'fas fa-chalkboard-teacher',
      text: 'Treinamento',
      subMenuItems: [
        {
          path: '/events',
          text: 'Eventos'
        }
      ]
    }
  ]

  menuItems: any[] = [{
    path: '#',
    class: 'far fa-folder-open',
    text: 'Cadastro',
    subMenuItems: [{
      path: '/user',
      text: 'Clientes'
    },
    {
      path: '/businesses',
      text: 'Profissionais'
    },
    {
      path: '/company',
      text: 'Empresas'
    }
  ]
  },  {
    path: '#',
    class: 'far fa-eye',
    text: 'Monitoramento',
    subMenuItems: [
      {
        path: '/task',
        text: 'Pedidos'
      },
      {
        path: '/chats',
        text: 'Chats'
      }
    ]
  },
  ...(this.userHasEmailFromTriider ? this.menuItemsToTriiderUsers : [])
];

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private loginService: LoginService
  ) { }


  ngOnInit() {
    this.profilePicture = this.currentUser.user.profile_picture;
  }

  get isAdmin() {
    return this.currentUser && this.currentUser.user.role === 'admin';
  }

  logout() {
    this.loginService.logout();
    this.router.navigate(['/login']);
  }

  openMenu() {
    if (this.isMenuOpen)
      this.isMenuOpen = false;
    else
      this.isMenuOpen = true;
  }
}
