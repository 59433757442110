import { Component, OnInit, Input } from "@angular/core";
import { SessionService } from "../services/session.service";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-iframe-bo",
  templateUrl: "./iframe-bo.component.html",
  styleUrls: ["./iframe-bo.component.scss"],
})
export class IframeBoComponent implements OnInit {
  srcIframe;
  @Input() page: string;
  parameter;
  constructor(
    private sessionService: SessionService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.parameter = +params["id"];
    });

    const { businessId, page } = this.route.snapshot.params;

    if (page) {
      this.page = page;
    }
    const url = window.location.href;
    if (
      url.includes("user") ||
      url.includes("company") ||
      url.includes("businesses") ||
      url.includes("task")
    ) {
      const args = url.split("/");
      if (+args[args.length - 1] && !this.parameter && !businessId) {
        this.parameter = +args[args.length - 1];
      }
    }

    const urlBo = `${environment.boUrl}?userId=${this.sessionService.currentUserValue.user.id}&sessionToken=${this.sessionService.currentUserValue.token}#!/${this.page}`;

    if (this.parameter) {
      this.srcIframe = this.sanitizer.bypassSecurityTrustResourceUrl(
        `${urlBo}/${this.parameter}`
      );
    } else if (businessId) {
      this.srcIframe = this.sanitizer.bypassSecurityTrustResourceUrl(
        `${urlBo}/${businessId}`
      );
    } else {
      this.srcIframe = this.sanitizer.bypassSecurityTrustResourceUrl(urlBo);
    }
  }
}
