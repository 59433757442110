import { Component, OnInit } from '@angular/core';
import { DistributionService } from 'src/app/services/distribution.service';
import { ServiceService } from 'src/app/services/service.service';
import { HelperService } from 'src/app/services/helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DistributionsComponent } from '../distributions.component';

@Component({
  selector: 'app-new-distribution-modal',
  templateUrl: './../new-distribution-modal/new-distribution-modal.component.html',
  styleUrls: ['./../new-distribution-modal/new-distribution-modal.component.scss']
})
export class NewDistributionModalComponent implements OnInit {
  services;
  cities;
  algorithms;
  markets = [{ text: "b2b" }, { text: "b2c" }];
  algorithmSelected;
  citiesSelected;
  servicesSelected;
  marketSelected;
  errorMessage;
  foundDistributions = [];

  constructor(
    private modalService: NgbModal,
    private distributionService: DistributionService,
    private serviceService: ServiceService,
    private helperService: HelperService,
    private distributionComponent: DistributionsComponent) { }

  ngOnInit() {
    this.distributionService.listAlgorithms().subscribe(result => this.algorithms = result.algorithms);

    this.serviceService.getAllServices().subscribe(result => this.services = result.services);

    this.helperService.getAllCities().subscribe(result => this.cities = result.cities);
  }

  open(content, size) {
    this.modalService.open(content, { size });
  }

  create(content) {
    if (this.algorithmSelected == undefined || this.citiesSelected == undefined
      || this.servicesSelected == undefined || this.marketSelected == null) {
      this.errorMessage = true;

      return;
    }

    var distribution = {
      city_id: this.citiesSelected.id,
      service_id: this.servicesSelected.id,
      market: this.marketSelected.text,
      algorithm_id: this.algorithmSelected.id,
      create: false
    }

    if (content == undefined)
      distribution.create = true;

    this.errorMessage = false;
    this.distributionService.create(distribution).subscribe((resp: any) => {
      if (!resp.success)
        console.log(`ERRO AO CRIAR DISTRIBUIÇÃO ${resp}`);

      if (resp.distributions.length > 0 && !distribution.create) {
        this.foundDistributions = resp.distributions.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());

        this.open(content, 'lg');

        return;
      }

      this.distributionComponent.ngOnInit();
      this.modalService.dismissAll();
    });
  }

  delete(id) {
    this.distributionComponent.delete(id).then(resp => {
      const filter = {
        cities: this.citiesSelected.id,
        services: this.servicesSelected.id,
        markets: this.marketSelected.text,
        offset: 0
      }

      this.distributionService.searchFilter(filter).subscribe(result => {
        if (!result.success)
          console.log(`ERRO AO FILTRAR DISTRIBUIÇÃO ${result}`);

        this.foundDistributions = result.distributions.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
      });
    });
  }
}
