import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class DistributionService {
  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) { }

  token = this.sessionService.currentUserValue.token;
  headers = {
    'api-token': this.token
  };

  searchFilter(filter) {
    return this.http.get<any>(`${environment.apiUrl}/distribution/search`, { params: filter });
  }

  listDistributions() {
    return this.http.get<any>(`${environment.apiUrl}/distributions`);
  }

  listAlgorithms() {
    return this.http.get<any>(`${environment.apiUrl}/algorithms`);
  }

  create(distribution) {
    return this.http.post(`${environment.apiUrl}/distribution`, { distribution });
  }

  delete(id) {
    return this.http.delete(`${environment.apiUrl}/distribution/${id}`);
  }
}
