import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { SessionService } from './../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
     private sessionService: SessionService) { }

  uploadPicture(file, currentUser) {
    let token = currentUser.token;
    return this.http.post<any>(`${environment.apiUrl}/image/profile`, file);
  }

  updateUser(user) {
    return this.http.put<any>(`${environment.apiUrl}/user/${user.id}`, { user });
  }

  changePassword(password, newPassword, token){
    return this.http
    .patch<any>(`${environment.apiUrl}/change-password/`, { password, newPassword });
  }
}
