import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

import { ServiceService } from '../services/service.service';
import { HelperService } from '../services/helper.service';
import { BusinessService } from '../services/business.service';

@Component({
  selector: 'app-search-filters-business',
  templateUrl: './search-filters-business.component.html',
  styleUrls: ['./search-filters-business.component.scss']
})
export class SearchFiltersBusinessComponent implements OnInit {
  @Output() outputBusinessess = new EventEmitter<any>();
  @Input() page = 1;
  statuses = [{ text: 'Ativo', value: true }, { text: 'Bloqueado', value: false }];
  statusesSelected = [];
  businessStatuses = [{ text: 'Valido', value: true }, { text: 'Não valido', value: false }];
  businessStatusesSelected = [];
  registerSteps = [{ text: 'Pré-cadastro', value: 'PRE' }, { text: 'Em andamento', value: 'DOING' }, { text: 'Enviado', value: 'SENT' }];
  registerStepsSelected = [];
  cities;
  citiesSelected = [];
  services;
  servicesSelected = [];
  isCompanyTypes = [{ text: 'Prestadores PJ', value: true }, { text: 'Não Prestadores PJ', value: false }];
  isCompanySelected = [];
  userIsAdminTypes = [{ text: 'Admin', value: true }, { text: 'Não Admin', value: false }];
  userIsAdminSelected = [];
  userIsSuspendedTypes = [{ text: 'Suspenso', value: true }, { text: 'Recebendo normal', value: false }];
  userIsSuspendedSelected = [];
  searchField = '';
  orderType = 'user_name';
  orderDir = 'desc';
  
  constructor(
    private serviceService: ServiceService,
    private helperService: HelperService,
    private BusinessService: BusinessService) { };


  async ngOnInit() {
    const servicesResponse = await this.serviceService.getAllServices().toPromise();
    this.services = servicesResponse.services;

    const citiesResponse = await this.helperService.getAllCities().toPromise();
    this.cities = citiesResponse.cities;
  }

  clear() {
    this.statusesSelected = [];
    this.businessStatusesSelected = [];
    this.registerStepsSelected = [];
    this.citiesSelected = [];
    this.servicesSelected = [];
    this.isCompanySelected = [];
    this.userIsAdminSelected = [];
    this.userIsSuspendedSelected = [];
    this.searchField = '';
    this.orderType = 'user_name';
    this.orderDir = 'desc';

    this.page = 1;
    this.search();
  }

  async search() {
    let filter = {
      term: this.searchField == null ? '' : this.searchField,
      user_status: this.selectIsEmpty(this.businessStatusesSelected) ? [JSON.stringify({ value: true })] : this.statusesSelected.map(status => { return JSON.stringify({ value: status.value }) }),
      company: this.selectIsEmpty(this.isCompanySelected) ? [] : this.isCompanySelected.map(isCompany => { return JSON.stringify({ value: isCompany.value }) }),
      is_admin: this.selectIsEmpty(this.userIsAdminSelected) ? [JSON.stringify({ value: false })] : this.userIsAdminSelected.map(isAdmin => { return JSON.stringify({ value: isAdmin.value }) }),
      suspended: this.selectIsEmpty(this.userIsSuspendedSelected) ? [] : this.userIsSuspendedSelected.map(isSuspended => { return JSON.stringify({ value: isSuspended.value }) }),
      business_status: this.selectIsEmpty(this.businessStatusesSelected) ? [JSON.stringify({ value: true })] : this.businessStatusesSelected.map(businessStatus => { return JSON.stringify({ value: businessStatus.value }) }),
      cities: this.selectIsEmpty(this.citiesSelected) ? [] : this.citiesSelected.map(city => { return JSON.stringify({ id: city.id }) }),
      services: this.selectIsEmpty(this.servicesSelected) ? [] : this.servicesSelected.map(service => { return JSON.stringify({ id: service.id }) }),
      register_step: this.selectIsEmpty(this.registerStepsSelected) ? [] : this.registerStepsSelected.map(step => { return JSON.stringify({ value: step.value }) }),
      order: this.orderType,
      order_dir: this.orderDir,
      count: 10,
      current_page: this.page,
      offset: this.page === 1 ? 0 : (this.page - 1) * 10
    };

    await this.BusinessService.listFilterPager(filter)
      .subscribe(resp => this.proList(resp.businesses));
  }

  proList(list) {
    this.outputBusinessess.emit(list);
  }

  selectIsEmpty(select) {
    return select.length == 0;
  }
}
