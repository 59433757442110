import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceService } from 'src/app/services/service.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-open-tasks-modal',
  templateUrl: './open-tasks-modal.component.html',
  styleUrls: ['./open-tasks-modal.component.scss']
})
export class OpenTasksModalComponent implements OnInit {
  services;
  cities;
  states;
  state;
  city;

  constructor(
    private modalService: NgbModal,
    private serviceService: ServiceService,
    private helperService: HelperService
  ) { }

  async ngOnInit() {
    this.states = (await this.helperService.getAllStates().toPromise()).states;
  }

  open(modal) {
    this.modalService.open(modal);
  }

  async setCities() {
    this.cities = (await this.helperService.getCities(this.state).toPromise()).cities;
  }

  async setServices(){
    this.services = (await this.serviceService.getServices(this.city).toPromise()).services;
  }

}
